import { Box, Heading, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { VolunteerAnecdote } from "./VolunteerAnecdote";

type VolunteerAnecdoteProps = {
  imageSrc: string;
  volunteerName: string;
  volunteerText: string;
};

type VolunteerAnecdotesProps = {
  anecdotes: VolunteerAnecdoteProps[];
};

export const VolunteerAnecdotes: React.FC<VolunteerAnecdotesProps> = ({
  anecdotes,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box>
        <Heading as="h2" variant="md">
          {t("main:volunteers.anecdotes.title")}
        </Heading>
      </Box>
      <Box>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          {anecdotes.map((anecdote, index) => (
            <VolunteerAnecdote key={index} {...anecdote} />
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};
