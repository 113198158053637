import { Box, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import Container from "../../../shared/components/layout/Container";
import Main from "../../../shared/components/main/Main";
import MainHeader from "../../../shared/components/main/MainHeader";
import MailButton from "../../components/buttons/MailButton";
import QuestionButton from "../../components/buttons/QuestionButton";
import SocialButton from "../../components/buttons/SocialButton";
import ContactCard from "../../components/cards/ContactCard";
import {
  FaqAccordion,
  useAccordionItems,
} from "../../components/faqAccordion/index";

const ContactPage: React.FC = () => {
  const { t } = useTranslation();
  const accordionItems = useAccordionItems("main:contact.accordion");

  return (
    <Main>
      <Container>
        <MainHeader title={t("main:contact.header")} />
        <Flex
          flexDirection={{ base: "column", md: "row" }}
          flexWrap="wrap"
          gap={{ base: "6", md: "8" }}
        >
          <ContactCard
            description={t("main:contact.cards.social.description")}
            title={t("main:contact.cards.social.title")}
          >
            <SocialButton social="fb" />
            <SocialButton social="insta" />
          </ContactCard>
          <ContactCard
            description={t("main:contact.cards.chat.description")}
            title={t("main:contact.cards.chat.title")}
          >
            <QuestionButton referer="contactPage" size="md" />
          </ContactCard>
          <ContactCard
            description={t("main:contact.cards.general.description")}
            title={t("main:contact.cards.general.title")}
          >
            <MailButton />
          </ContactCard>
        </Flex>
        <Heading as="h2" my="2em" textAlign="left" variant="sm" w="full">
          Veelgestelde vragen
        </Heading>
        <Box flex="1 1 0px" maxW="880px" mt={{ base: "2em", md: "0" }}>
          <FaqAccordion accordionItems={accordionItems} variant="home" />
        </Box>
      </Container>
    </Main>
  );
};

export default ContactPage;
