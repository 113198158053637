import { Button } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FiBook, FiChevronRight } from "react-icons/fi";

const ReadButton = ({
  children,
  href,
  size = "lg",
  variant,
}: {
  children?: string | null;
  href?: string;
  size?: string;
  variant?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Button
      as="a"
      href={href}
      leftIcon={<FiBook />}
      rightIcon={<FiChevronRight />}
      size={size}
      variant={variant}
    >
      {children ?? t("main:readStory")}
    </Button>
  );
};

export default ReadButton;
