import React from "react";

import AsyncResources from "../../../shared/components/async/AsyncResources";
import MainFooterItem from "../../../shared/components/main/MainFooterItem";
import { EmptyResource, ResourceType } from "../../../types/serializers";
import EmbeddedDirectQuestion from "../embed/EmbeddedDirectQuestion";

export interface PillarPageBlockDirectQuestionsProps {
  directQuestions?: EmptyResource<ResourceType.EmbeddedDirectQuestion>[];
}

const PillarPageBlockDirectQuestions = ({
  directQuestions,
}: PillarPageBlockDirectQuestionsProps) => {
  if (!directQuestions || directQuestions.length === 0) {
    return null;
  }

  return (
    <MainFooterItem>
      <AsyncResources resources={directQuestions}>
        {(embeddedDirectQuestion) => (
          <EmbeddedDirectQuestion
            embeddedDirectQuestion={embeddedDirectQuestion}
          />
        )}
      </AsyncResources>
    </MainFooterItem>
  );
};

export default PillarPageBlockDirectQuestions;
