import { Button, Flex, Heading, VStack } from "@chakra-ui/react";
import React from "react";

import Container from "../../../shared/components/layout/Container";
import Main from "../../../shared/components/main/Main";
import MainHeader from "../../../shared/components/main/MainHeader";

import Download from "./downloads/Eerder Stoppen - Er kan meer dan je denkt.pdf";

const DocumentStopWorkPage = () => {
  return (
    <Main>
      <Container>
        <MainHeader title="Eerder stoppen met werken stappenplan" />
        <Flex justify="center">
          <VStack align="start" maxW="800px">
            <p>
              Veel mensen denken dat eerder stoppen met werken niet mogelijk is.
              Daarmee blijft voor de meeste mensen eerder stoppen met werken een
              droom. Maar wat als het anders kan? Dit e-book brengt je met
              praktische stappen dichterbij om van eerder stoppen met werken
              werkelijkheid te maken.
            </p>
            <Heading as="h2" variant="lg">
              Klik hieronder om het e-book te downloaden
            </Heading>
            <Button download as="a" href={Download} variant="outline">
              Download het Stappenplan e-book
            </Button>
          </VStack>
        </Flex>
      </Container>
    </Main>
  );
};

export default DocumentStopWorkPage;
