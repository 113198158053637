import { useLocation } from "react-router-dom";

const usePropsFromQuery = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  return propsFromQuery(searchParams);
};

export const propsFromQuery = (searchParams: URLSearchParams) => {
  const tagline = searchParams.get("tagline") ?? undefined;
  const buttonSubText = searchParams.get("button-subtext") ?? undefined;
  const buttonText = searchParams.get("button-text") ?? undefined;
  const id = searchParams.get("id")
    ? parseInt(searchParams.get("id")!)
    : undefined;
  const testMode = searchParams.get("test-mode") === "true";

  return {
    buttonSubText,
    buttonText,
    id,
    tagline,
    testMode,
  };
};

export default usePropsFromQuery;
