import { Button, ButtonProps } from "@chakra-ui/react";
import React, { MouseEventHandler, useContext } from "react";
import { useTranslation } from "react-i18next";
import { FiChevronRight, FiMessageCircle } from "react-icons/fi";

import { trackEvent } from "../../../shared/lib/analytics";
import { QuestionFlowContext } from "../../../shared/lib/questionFlowContext";

interface QuestionButtonProps extends ButtonProps {
  children?: string | null;
  referer: string;
  showLeftIcon?: boolean;
  showRightIcon?: boolean;
  trackEventName?: string;
}

const QuestionButton = ({
  children,
  onClick,
  referer,
  size = "lg",
  trackEventName = "click_question_button",
  showLeftIcon = true,
  showRightIcon = false,
  ...otherProps
}: QuestionButtonProps) => {
  const { openFlow } = useContext(QuestionFlowContext);
  const { t } = useTranslation();
  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    trackEvent(trackEventName);
    if (onClick) {
      onClick(e);
    } else {
      openFlow({ referer });
    }
  };

  return (
    <Button
      leftIcon={showLeftIcon ? <FiMessageCircle /> : undefined}
      rightIcon={showRightIcon ? <FiChevronRight /> : undefined}
      size={size}
      {...otherProps}
      onClick={handleClick}
    >
      {children ?? t("main:askQuestion")}
    </Button>
  );
};

export default QuestionButton;
