import { Box, Heading } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import Main from "../../../shared/components/main/Main";
import { AboutAnecdotes } from "../../components/about/AboutAnecdotes";
import AboutHeader from "../../components/about/AboutHeader";
import ArieIcon from "../../components/about/images/arie.webp";
import MarianIcon from "../../components/about/images/marian.webp";
import MarjaIcon from "../../components/about/images/marja.webp";
import { FaqAccordion, useAccordionItems } from "../../components/faqAccordion";
import PageRow from "../../layouts/PageRow";

const AboutPage = () => {
  const { t } = useTranslation();
  const accordionItems = useAccordionItems("main:about.accordion");

  const userAnecdote = [
    {
      imageSrc: MarianIcon,
      name: t("main:about.anecdotes.marian.name"),
      text: t("main:about.anecdotes.marian.text"),
    },
    {
      imageSrc: ArieIcon,
      name: t("main:about.anecdotes.arie.name"),
      text: t("main:about.anecdotes.arie.text"),
    },
    {
      imageSrc: MarjaIcon,
      name: t("main:about.anecdotes.marja.name"),
      text: t("main:about.anecdotes.marja.text"),
    },
  ];

  return (
    <Main marginBottom={0}>
      <AboutHeader />
      <PageRow backdrop={true} paddingY={8}>
        <AboutAnecdotes anecdotes={userAnecdote} />
      </PageRow>
      <PageRow paddingY={0}>
        <Heading as="h2" my="2em" textAlign="left" variant="sm" w="full">
          {t("main:about.accordion_title")}
        </Heading>
        <Box flex="1 1 0px" maxW="880px" mb="4em" mt={{ base: "2em", md: "0" }}>
          <FaqAccordion accordionItems={accordionItems} variant="home" />
        </Box>
      </PageRow>
    </Main>
  );
};

export default AboutPage;
