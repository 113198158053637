import {
  Flex,
  Heading,
  ListItem,
  OrderedList,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React from "react";

import Container from "../../../shared/components/layout/Container";
import Link from "../../../shared/components/link";
import Main from "../../../shared/components/main/Main";
import MainHeader from "../../../shared/components/main/MainHeader";

const VolunteersGuidelinesPage = () => {
  return (
    <Main>
      <Container>
        <MainHeader title="Richtlijnen voor vrijwilligers" />
        <Flex justify="center">
          <VStack align="start" maxW="800px">
            <Heading as="h2" mb={0} variant="lg">
              Richtlijnen voor vrijwilligers op Kandoor.nl
            </Heading>
            <p>
              Het vertrouwen in financiële instellingen is historisch laag. Daar
              is genoeg over gezegd en geschreven. Wij willen vooruitkijken. Dat
              vooruitkijken begint met nadenken over een antwoord op de kapitale
              vraag: hoe gaan we een nieuw financieel systeem inrichten? Niet
              gebaseerd op ieder-voor-zich maar samen voor elkaar. Zoals wij
              mensen van nature zijn: sociaal en behulpzaam. Van daaruit hebben
              wij Kandoor.nl ontwikkeld. Een platform waar vrijwilligers,
              antwoord geven op vragen rondom geldzaken aan mensen die
              financiële stress ondervinden. De vrijwilligers geven geen
              financieel advies. Ze geven informatie en richting. Over pensioen,
              zorg, arbeid of wonen. Het is eigenlijk heel menselijk. En de
              vraagsteller kan daarna zelf een beslissing nemen of door richting
              een pensioenfonds, financieel adviseur of bank.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Wat is wel en niet toegestaan op Kandoor.nl?
            </Heading>
            <p>
              Vrijwilligers behandelen gebruikers en andere vrijwilligers met
              respect. Zij maken geen racistische, beledigende, seksistische,
              discriminerende, bedreigende of andere kwetsende opmerkingen. Ze
              gebruiken geen grof taalgebruik, maken geen misplaatste grappen en
              vallen andere gebruikers of vrijwilligers op geen enkele manier
              lastig.
            </p>
            <p>Verder is het gebruikers niet toegestaan om:</p>
            <OrderedList>
              <ListItem>
                de werking van het online platform te storen door vaak dezelfde
                tekst of tekens te plaatsen;
              </ListItem>
              <ListItem>
                de werking van het online platform, de website, andere computers
                of systemen van Kandoor en andere gebruikers te verstoren.
                Daaronder valt:
                <UnorderedList>
                  <ListItem>
                    het verspreiden van virussen (inclusief Trojaanse paarden,
                    tijdbommen en dergelijke);
                  </ListItem>
                  <ListItem>
                    het hacken of ander toegang geven tot de computers of
                    systemen van Kandoor of andere gebruikers;
                  </ListItem>
                  <ListItem>
                    het wissen van bestanden op computers of systemen van
                    Kandoor of andere gebruikers.
                  </ListItem>
                </UnorderedList>
              </ListItem>
            </OrderedList>
            <p>Het online platform mag niet worden gebruikt voor:</p>
            <OrderedList>
              <ListItem>
                commerciële activiteiten. Bijvoorbeeld het aanprijzen of de
                verkoop van producten of diensten, het sturen van commerciële
                boodschappen (spam), het aanbieden van (interactieve) spelletjes
                of het aanprijzen van andere chatkanalen of websites;
              </ListItem>
              <ListItem>
                wettelijk niet toegestane activiteiten en handelingen, zoals de
                handel in soft- en harddrugs, de handel in of verspreiding van
                (kinder)porno, de handel of verspreiding van bijvoorbeeld
                mp3-bestanden of andere zaken waar auteursrecht op zit.
              </ListItem>
            </OrderedList>
            <p>Vrijwilligers zullen zich onthouden van:</p>
            <OrderedList>
              <ListItem>
                het hengelen naar contacten. Kandoor.nl is geen datingsite;
              </ListItem>
              <ListItem>
                het vragen om antwoorden en/of reacties via privébericht,
                telefoon of per e-mail.
              </ListItem>
            </OrderedList>
            <p>
              Vrijwilligers mogen niet doen alsof ze moderator van het online
              platform zijn of andermans identiteit gebruiken. Zij zullen
              aanwijzingen van de redactie en moderators steeds opvolgen.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Gedragsregels bij het plaatsen van berichten
            </Heading>
            <p>
              Wees helder in de beantwoording van de vraag. Houd de tekst van je
              bericht kort en bondig.
            </p>
            <p>
              Wees begripvol en respectvol richting andere gebruikers,
              vrijwilligers en moderators.
            </p>
            <p>
              Gebruik geen shockerende of ongepaste afbeeldingen of teksten bij
              je reactie.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Voorzichtigheid
            </Heading>
            <p>
              Kandoor adviseert vrijwilligers om voorzichtig te zijn bij het
              gebruik van het online platform. Een paar tips:
            </p>
            <UnorderedList>
              <ListItem>
                Maak geen persoonlijke informatie aan gebruikers bekend.
              </ListItem>
              <ListItem>
                Verplaats je in de vraagsteller. Verwacht deze meer dan een
                richting of informatie? Leg dan nogmaals uit dat je geen
                financieel advies geeft, maar wel meedenkt.
              </ListItem>
              <ListItem>
                Zorg voor goede beveiliging van je computer(s) en andere
                systemen. Installeer hiervoor software of hardware die toegang
                tot jouw computer(s) of systemen blokkeert. Zorg er daarnaast
                voor dat jouw computer(s) of systemen geen virussen verspreiden.
                Denk hierbij aan firewalls en antivirussoftware. Zorg er tot
                slot voor dat deze software up-to-date is.
              </ListItem>
            </UnorderedList>
            <Heading as="h2" mb={0} variant="lg">
              Aansprakelijkheid
            </Heading>
            <p>
              Vrijwilligers zijn verantwoordelijk voor de inhoud van hun
              berichten. Kandoor aanvaardt geen aansprakelijkheid voor onjuiste
              informatie op het online platform.
            </p>
            <p>
              Vrijwilligers die reageren op berichten van gebruikers, geven geen
              financieel advies. Zij kunnen dan ook niet aansprakelijk worden
              gehouden voor schade die een gebruiker claimt in verband met
              ondeugdelijk financieel advies.
            </p>
            <p>
              Kandoor is niet aansprakelijk voor enige schade als gevolg van het
              gebruik van het online platform door vrijwilligers. De financieel
              vrijwilliger vrijwaart hierbij Kandoor voor alle schade en kosten
              die Kandoor lijdt of maakt als gevolg van aanspraken van
              gebruikers of derden, die worden veroorzaakt door het in strijd
              met deze richtlijnen of anderszins onrechtmatig handelen van de
              financieel vrijwilliger op het online platform.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Copyright
            </Heading>
            <p>
              Kandoor behoudt het recht, tenzij anders overeen gekomen met de
              financieel vrijwilliger, ingezonden materiaal in te korten en aan
              te passen. Een financieel vrijwilliger mag geen auteursrechtelijke
              beschermde werken of andere in Kandoor.nl opgeslagen informatie
              en/of dialogen openbaar maken of verveelvoudigen zonder
              toestemming van Kandoor. Kandoor registreert de gegevens die nodig
              zijn voor de dienstverlening. De algemene inhoud van de gesprekken
              gebruikt Kandoor voor de verdere ontwikkeling van de
              dienstverlening.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Intellectuele eigendomsrechten
            </Heading>
            <p>
              Alle (intellectuele) eigendomsrechten met betrekking tot de naam
              Kandoor en het logo van Kandoor, inclusief de merkrechten,
              berusten uitsluitend bij Kandoor. De (auteurs-)rechten met
              betrekking tot het format en de inhoud van de website Kandoor
              (onder meer de daarin opgenomen gegevens, afbeeldingen, geluiden,
              teksten, look &amp; feel, lay-out) berusten uitsluitend bij APG
              Groep N.V.. Zonder voorafgaande schriftelijke toestemming van APG
              Groep N.V. is het onder meer niet toegestaan de website
              Kandoor.nl, of enig onderdeel daarvan, openbaar te maken en/of te
              (doen) verveelvoudigen (waaronder in ieder geval begrepen
              aanbieden, (doen) vervaardigen, verkopen, leveren of anderszins
              verhandelen). Zogenaamde ‘framing’ valt uitdrukkelijk onder het op
              ontoelaatbare wijze openbaar maken/verveelvoudigen van (onderdelen
              van) de website.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Klachtenregeling
            </Heading>
            <p>
              Vind je dat het gedrag van andere gebruikers of van vrijwilligers
              niet past binnen de gebruikersvoorwaarden? Stuur dan een e-mail
              aan <Link href="mailto:info@kandoor.nl">info@kandoor.nl</Link>.
              Als we merken dat een gebruiker of financieel vrijwilliger zich
              niet aan de gebruikersvoorwaarden houdt, nemen we maatregelen. Zo
              passen we bijvoorbeeld berichten aan of verwijderen we een
              bericht. Soms vragen we een gebruiker of financieel vrijwilliger
              de berichten zelf aan te passen of te verwijderen. Ook kunnen we
              een gebruiker of financieel vrijwilliger tijdelijk of permanent
              uitsluiten van toegang tot het Kandoorplatform.
            </p>
            <p>
              Kandoor behoudt zich het recht voor om wijzigingen aan te brengen
              in deze richtlijnen. Wij adviseren je deze richtlijnen met
              regelmaat te lezen.
            </p>
            <p>Deze pagina is voor het laatst opgemaakt op 1 aug 2023.</p>
          </VStack>
        </Flex>
      </Container>
    </Main>
  );
};

export default VolunteersGuidelinesPage;
