import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import Container from "../../../shared/components/layout/Container";
import MainHeader from "../../../shared/components/main/MainHeader";
import QuestionButton from "../buttons/QuestionButton";

const VolunteerHeader = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <MainHeader title={t("main:volunteers.header.title")}>
        <Flex
          borderBottom="1px"
          borderColor="gray.light"
          direction={{ base: "column", md: "row" }}
          gap={4}
          paddingBottom="2em"
        >
          <Box flex="1 1 0px" pr={8}>
            <Box lineHeight="2" mb="2em">
              {t("main:volunteers.header.intro")}
            </Box>
            <QuestionButton
              referer="volunteerHeader"
              showRightIcon={true}
              variant="solid"
            >
              {t("main:volunteers.header.button")}
            </QuestionButton>
          </Box>
          <Box
            data-chromatic="ignore"
            flex="1 1 0px"
            mt={{ base: "2em", md: "0" }}
          >
            <iframe
              allowFullScreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              height="400"
              src="https://www.youtube.com/embed/3r-Yhb5HmP4"
              style={{ borderRadius: "8px" }}
              title="YouTube video player"
              width="100%"
            />
          </Box>
        </Flex>
      </MainHeader>
    </Container>
  );
};

export default VolunteerHeader;
