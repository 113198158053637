import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import Container from "../../../shared/components/layout/Container";
import MainHeader from "../../../shared/components/main/MainHeader";
import RichText from "../../../shared/components/richText";

const AboutHeader = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <MainHeader title={t("main:about.header.title")}>
        <Flex
          direction={{ base: "column", md: "row" }}
          gap={4}
          marginBottom="2em"
        >
          <Box flex="1 1 0px" lineHeight="2" pr={8}>
            <Heading as="h2" mb={1} variant="xs">
              <Text>{t("main:about.header.subheader")}</Text>
            </Heading>
            <RichText>{t("main:about.header.content")!}</RichText>
          </Box>
          <Box
            data-chromatic="ignore"
            flex="1 1 0px"
            mt={{ base: "2em", md: "0" }}
          >
            <iframe
              allowFullScreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              height="400"
              src="https://www.youtube.com/embed/OysCUK8Y5z0"
              style={{ borderRadius: "8px" }}
              title="YouTube video player"
              width="100%"
            />
          </Box>
        </Flex>
      </MainHeader>
    </Container>
  );
};

export default AboutHeader;
