import { Heading } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import AsyncResources from "../../../shared/components/async/AsyncResources";
import MainFooterItem from "../../../shared/components/main/MainFooterItem";
import { EmptyResource, ResourceType } from "../../../types/serializers";
import EmbeddedDirectQuestion from "../embed/EmbeddedDirectQuestion";

export interface BlogDirectQuestionsProps {
  directQuestions: EmptyResource<ResourceType.EmbeddedDirectQuestion>[];
}

const BlogDirectQuestions = ({ directQuestions }: BlogDirectQuestionsProps) => {
  const { t } = useTranslation();

  if (directQuestions.length === 0) {
    return null;
  }

  return (
    <MainFooterItem>
      <Heading as="h2" variant="lg">
        {t("main:directQuestions.header")}
      </Heading>
      <AsyncResources resources={directQuestions}>
        {(embeddedDirectQuestion) => (
          <EmbeddedDirectQuestion
            embeddedDirectQuestion={embeddedDirectQuestion}
          />
        )}
      </AsyncResources>
    </MainFooterItem>
  );
};

export default BlogDirectQuestions;
