import { Container } from "@chakra-ui/react";
import React from "react";

import Main from "../../../shared/components/main/Main";
import Statemachine from "../../../shared/components/statemachine";
import {
  Context,
  StatemachineProps,
} from "../../../shared/components/statemachine/contexts/StatemachineContext";
import usePropsFromQuery from "../../../shared/components/statemachine/lib/usePropsFromQuery";

interface StatemachinePageProps {
  propsOverride?: Omit<StatemachineProps, "context">;
}

const StatemachinePage = ({ propsOverride }: StatemachinePageProps) => {
  const statemachineProps = usePropsFromQuery();

  return (
    <Main
      marginBottom={{ base: "1em", md: "10vh" }}
      marginTop={{ base: "1em", md: "10vh" }}
      paddingX={{ base: "1em", md: undefined }}
    >
      <Container maxWidth="container.md" paddingX={0}>
        <Statemachine
          context={Context.FullPage}
          {...(propsOverride ?? statemachineProps)}
        />
      </Container>
    </Main>
  );
};

export default StatemachinePage;
