import { Alert, AlertIcon, AlertTitle } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import EditResourceButton from "../../../shared/components/button/EditResourceButton";
import Image from "../../../shared/components/image";
import Main from "../../../shared/components/main/Main";
import MainBody from "../../../shared/components/main/MainBody";
import MainFooter from "../../../shared/components/main/MainFooter";
import MainHeader from "../../../shared/components/main/MainHeader";
import MainLeft from "../../../shared/components/main/MainLeft";
import RichText from "../../../shared/components/richText";
import { parseHTML } from "../../../shared/components/richText/lib/parseHTML";
import { parseMarkdown } from "../../../shared/components/richText/lib/parseMarkdown";
import useIndexRoute from "../../../shared/components/store/hooks/useIndexRoute";
import useResourceRoute from "../../../shared/components/store/hooks/useResourceRoute";
import useResource from "../../../shared/hooks/useResource";
import {
  BlogResource,
  CollectionOrderDirection,
  ResourceType,
} from "../../../types/serializers";
import { AboutKandoorAccordion } from "../../components/blog/aboutKandoor";
import BlogDetails from "../../components/blog/BlogDetails";
import BlogDirectQuestions from "../../components/blog/BlogDirectQuestions";
import BlogDisclaimer from "../../components/blog/BlogDisclaimer";
import BlogNewsletterForm from "../../components/blog/BlogNewsletterForm";
import BlogRecommendations from "../../components/blog/BlogRecommendations";
import BlogRelatedCategories from "../../components/blog/BlogRelatedCategories";
import HelpedQuery from "../../components/blog/HelpedQuery";
import TableOfContents from "../../components/blog/TableOfContents";
import Breadcrumbs from "../../components/breadcrumbs";
import Layout3_6_3 from "../../layouts/Layout3_6_3";

export const formatPageViews = (pageViews: number | undefined) => {
  return pageViews?.toLocaleString("nl-NL", {
    maximumFractionDigits: 0,
    useGrouping: true,
  });
};

const BlogShowPage = ({ resource }: { resource: BlogResource }) => {
  const slateValue = useMemo(
    () => parseHTML(parseMarkdown(resource.attributes.markdown)),
    [resource.attributes.markdown]
  );

  const { t } = useTranslation();

  const pillarPage = useResource(resource.relationships.pillarPage.data);
  const coverImage = useResource(resource.relationships.coverImage.data);
  const route = useResourceRoute(pillarPage)!;
  const indexRoute = useIndexRoute(ResourceType.PillarPage);

  return (
    <Main marginBottom={0}>
      <Layout3_6_3>
        <Breadcrumbs
          items={[
            { href: "/", label: t("main:breadcrumbs.home") },
            {
              href: indexRoute,
              label: t("main:pillarPages.breadcrumb"),
            },
            pillarPage
              ? {
                  href: route,
                  label: pillarPage.attributes.title,
                }
              : undefined,
          ]}
        />
        <MainLeft>
          <TableOfContents slateValue={slateValue} />
        </MainLeft>
        <MainHeader
          pullRight={<EditResourceButton resource={resource} />}
          title={resource.attributes.title}
        >
          <BlogDetails author blog={resource} />
          {!resource.attributes.published && (
            <Alert my={5} status="warning">
              <AlertIcon />
              <AlertTitle>{t("main:blogs.unpublished")}</AlertTitle>
            </Alert>
          )}
          <RichText textStyle="bodyBlog">{resource.attributes.intro}</RichText>
          {coverImage && (
            <Image
              alt={coverImage.attributes.alt}
              baseEncoded={coverImage.attributes.baseEncoded}
              caption={coverImage.attributes.caption}
              height={coverImage.attributes.coverHeight}
              marginBottom="2em"
              src={coverImage.attributes.coverUrl}
              width={coverImage.attributes.coverWidth}
            />
          )}
        </MainHeader>
        <MainBody>
          <RichText slateValue={slateValue} textStyle="bodyBlog" />
        </MainBody>
        <MainFooter>
          <HelpedQuery />
          <BlogRelatedCategories blog={resource} />
          <BlogDisclaimer />
          <BlogDirectQuestions
            directQuestions={
              resource.relationships.embeddedDirectQuestions.data ?? []
            }
          />
          <BlogRecommendations
            collectionOptions={{
              filter: {
                categoryList: resource.attributes.categoryList,
                published: ["true"],
                slug: [`!${resource.id}`],
              },
              order: { pageViews: CollectionOrderDirection.Desc },
              pageSize: 2,
            }}
          />
          <AboutKandoorAccordion />
        </MainFooter>
      </Layout3_6_3>
      <BlogNewsletterForm />
    </Main>
  );
};

export default BlogShowPage;
