import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";

type VolunteerAnecdoteProps = {
  imageSrc: string;
  volunteerName: string;
  volunteerText: string;
};

export const VolunteerAnecdote: React.FC<VolunteerAnecdoteProps> = ({
  imageSrc,
  volunteerName,
  volunteerText,
}) => {
  return (
    <Flex p={2}>
      <Image
        alt={`${volunteerName}'s profile`}
        borderRadius="full"
        boxSize="80px"
        mr="1em"
        mt="1.5em"
        objectFit="cover"
        src={imageSrc}
      />
      <Box>
        <Text fontWeight="bold" mb={1}>
          {volunteerName}
        </Text>
        <Text>{volunteerText}</Text>
      </Box>
    </Flex>
  );
};
