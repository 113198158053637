import { Button } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FiMail } from "react-icons/fi";

const MailButton = ({
  children,
  size = "md",
}: {
  children?: string;
  size?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Button
      as="a"
      href="mailto:info@kandoor.nl"
      leftIcon={<FiMail />}
      size={size}
      variant="outline"
    >
      {children ?? t("main:mailUs")}
    </Button>
  );
};
export default MailButton;
