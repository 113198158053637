import { Flex, Heading, VStack } from "@chakra-ui/react";
import React from "react";

import Container from "../../../shared/components/layout/Container";
import Link from "../../../shared/components/link";
import Main from "../../../shared/components/main/Main";
import MainHeader from "../../../shared/components/main/MainHeader";

const PrivacyStatementPage = () => {
  return (
    <Main>
      <Container>
        <MainHeader title="Privacyverklaring" />
        <Flex justify="center">
          <VStack align="start" maxW="800px">
            <Heading as="h2" mb={0} variant="lg">
              Door de gebruikersvoorwaarden te accepteren, ga je ook akkoord met
              onze Privacyverklaring
            </Heading>
            <p>
              Kandoor vindt de bescherming van jouw gegevens belangrijk. Wij
              behandelen jouw persoonsgegevens vertrouwelijk en zorgvuldig. Wij
              houden ons aan de Algemene Verordening Gegevensbescherming
              (“AVG”). Kandoor is een onderdeel van APG Groep NV.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Waarvoor gebruikt Kandoor jouw gegevens?
            </Heading>
            <p>
              Wij gebruiken jouw gegevens om jouw financiële vraag op een juiste
              en zorgvuldige manier te beantwoorden.
            </p>
            <p>
              Jouw e-mailadres wordt gebruikt om jou te helpen om in te loggen
              op het Kandoor platform. Je kunt dan jouw gestelde vragen bekijken
              en de antwoorden die Kandoor heeft gegeven. Ook kun je dan met de
              vrijwilligers chatten.
            </p>
            <p>
              In sommige gevallen stelt de chatbot jou een vraag. Jouw antwoord
              wordt opgeslagen in jouw gebruikersprofiel. Voorbeelden hiervan
              zijn: jouw pensioenfonds, jouw leeftijdsgroep, jouw type
              huishouden.
              <br />
            </p>
            <p>
              <strong>Voorbeeld:</strong>
            </p>
            <em>
              Vraag 1, gesteld op 26-01-2024
              <br />
              <strong>Vraagsteller</strong>: Heb ik recht op zorgtoeslag? <br />
              <strong>Kandoor wizard:</strong>Heb je een toeslagpartner?
              <br /> [Ja] of [Nee]
              <br />
              <strong>Vraagsteller</strong>: Nee
              <br />
              <strong>Kandoor wizard:</strong>Is je bruto jaarinkomen in 2024
              lager dan € 37.496?
              <br /> [Ja] [Nee] <br />
              <strong>Vraagsteller</strong>: Nee <br />
              <strong>Kandoor wizard:</strong> Helaas heb je geen recht op
              zorgtoeslag. Je bruto jaarinkomen in 2024 mag niet hoger zijn dan
              € 37.496.
            </em>
            <p>
              <em>
                Vraag 2, gesteld op 03-03-2024
                <br />
                <strong>Vraagsteller</strong>: Kan ik beter loonheffingskorting
                op mijn pensioen of mijn AOW toepassen?
                <br /> [Ja] of [Nee]
                <br />
                <strong>Vraagsteller</strong>: Ja
                <br />
                <strong>Kandoor wizard:</strong>Woon je met iemand samen?
                <br /> [Ja] [Nee] <br />
                <strong>Vraagsteller</strong>: Ja <br />
                <strong>Kandoor wizard:</strong> Met wie woon je samen?
                <br /> [Partner] of [Kind, vader en/of moeder] of [Iemand
                anders] <br />
                <strong>Vraagsteller</strong>: Partner
                <strong>Kandoor wizard:</strong>Is je aanvullend pensioen minder
                dan €18.000 bruto per jaar?
                <br /> [Ja] [Nee] <br />
                <strong>Vraagsteller</strong>: Ja <br />
                <strong>Kandoor wizard:</strong> Is je aanvullend pensioen meer
                dan €13.200 bruto per jaar?
                <br /> [Ja] [Nee] <br />
                <strong>Vraagsteller</strong>: Ja <br />
                <strong>Kandoor wizard:</strong>Met een aanvullend pensioen
                tussen de €13.200 en €18.000 bruto per jaar, is het verstandig
                om je loonheffingskorting op je pensioen te laten toepassen.
                <br />
              </em>
            </p>
            <p>
              Het is mogelijk om je in te schrijven op de nieuwsbrief met
              handige financiële tips van Kandoor. Om onze nieuwsbrieven te
              ontvangen, dien je expliciete toestemming te geven. Je hebt te
              allen tijde het recht om jouw gegeven toestemming in te trekken.
            </p>
            <p>
              Ook jouw IP-adres (zie cookies) wordt opgeslagen, als je hiertoe
              een cookie hebt geaccepteerd. Niet-essentiële cookies worden
              alleen opgeslagen op jouw computer, als je ons hiervoor
              toestemming hebt gegeven.{" "}
              <a
                href="https://kandoor.nl/cookies"
                rel="noreferrer"
                target="_blank"
              >
                Bekijk hier onze cookieverklaring.
              </a>
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Jij hebt zelf de regie over jouw opgeslagen gegevens
            </Heading>
            <p>
              Bij Kandoor kun je zelf jouw opgeslagen gegevens inzien,
              aanpassen, leegmaken of verwijderen. Dit kan via het tabje
              ‘profiel’ en is zichtbaar als je bent ingelogd met je e-mailadres.
            </p>
            <p>
              Let op: Als je de gegevens hebt verwijderd, worden deze gegevens
              opnieuw opgeslagen als de wizard hier opnieuw om vraagt. Maar je
              kunt deze gegevens opnieuw verwijderen.
            </p>
            <p>
              Je kunt zelf je vragen, gegevens of complete account verwijderen
              in je profiel. Ook kun je altijd vragen om correctie, aanvulling,
              beperking of verwijdering van jouw gegevens door Kandoor. Dit kan
              door te mailen naar{" "}
              <Link href="mailto:info@kandoor.nl">info@kandoor.nl</Link>.
            </p>
            <p>
              Verder heb je het recht om bezwaar te maken tegen de verwerking en
              heb je het recht om Kandoor te vragen om jouw opgeslagen gegevens
              aan jou te sturen, bijvoorbeeld in een Excelbestand. Dit heet
              “gegevensoverdraagbaarheid” of “data-portabiliteit”. Als je dit
              wilt, kun je hiervoor een bericht sturen naar{" "}
              <Link href="mailto:info@kandoor.nl">info@kandoor.nl</Link>.
            </p>
            <p>
              Mocht je het niet eens zijn met de verwerkingen of het handelen
              van Kandoor hierin, dat staat het je altijd vrij een klacht in te
              dienen bij de toezichthoudende autoriteit. Dit is de Autoriteit
              Persoonsgegevens. Zie{" "}
              <a
                href="https://www.autoriteitpersoonsgegevens.nl"
                rel="noreferrer"
                target="_blank"
              >
                www.autoriteitpersoonsgegevens.nl.
              </a>
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Andere betrokken partijen onder onze controle
            </Heading>
            <p>
              Om Kandoor zijn werk te laten doen voor jou, maakt Kandoor ook
              gebruik van de diensten van enkele andere IT-leveranciers. Kandoor
              verwerkt in de basis gegevens in de EU en EEA. (Voor sommige
              diensten worden ook gegevens in de Verenigde Staten verwerkt).
              Hiervoor zijn de nodige afspraken met de betreffende leveranciers
              gemaakt.Als je hier vragen over hebt of meer kun je contact
              opnemen met Kandoor via{" "}
              <Link href="mailto:info@kandoor.nl">info@kandoor.nl</Link>.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Bewaren
            </Heading>
            <p>
              In lijn met de AVG (Algemene verordening gegevensbescherming)
              bewaren wij gegevens niet langer dan noodzakelijk. Daarom worden
              inactieve accounts, die langer dan 2 jaar inactief zijn geweest,
              automatisch verwijderd en eventuele algemene (niet-persoonlijke)
              data geanonimiseerd. Dit betekent dat namen, emailadressen, jouw
              berichten en andere persoonsgegevens verwijderd zullen worden. Ook
              kun je altijd zelf je gegevens of je complete account verwijderen
              in je profiel.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Beveiliging
            </Heading>
            <p>
              Kandoor neemt de bescherming van jouw persoonsgegevens serieus. We
              nemen passende maatregelen om misbruik, verlies, onbevoegde
              toegang, ongewenste openbaarmaking en ongeoorloofde wijziging
              tegen te gaan. Kandoor maakt gebruik van SSL certificaten voor de
              beveiliging van de communicatie tussen jouw browser en de Kandoor
              website. De gegevens worden versleuteld verstuurd zodat
              onbevoegden deze gegevens niet kunnen lezen.
            </p>
            <p>
              De servers van Kandoor zijn daarnaast goed beveiligd en kunnen
              alleen worden benaderd via SSH beveiligde verbindingen. De
              gegevens kunnen hierdoor niet worden onderschept.
            </p>
            <p>
              De toegang tot de data voor medewerkers van Kandoor is beperkt en
              aan strenge regels gebonden. Er worden regelmatig
              beveiligingsaudits uitgevoerd.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Wijzigen Privacyverklaring
            </Heading>
            <p>
              Kandoor heeft het recht om deze Privacyverklaring te wijzigen.
              Lees deze Privacyverklaring dus regelmatig. Deze Privacyverklaring
              is voor het laatst gewijzigd op 23 mei 2024. Voor vragen over de
              Privacyverklaring kun je contact opnemen met Kandoor via{" "}
              <Link href="mailto:info@kandoor.nl">info@kandoor.nl</Link>.
            </p>
          </VStack>
        </Flex>
      </Container>
    </Main>
  );
};

export default PrivacyStatementPage;
