import { Button, Flex, Heading, VStack } from "@chakra-ui/react";
import React from "react";

import Container from "../../../shared/components/layout/Container";
import Main from "../../../shared/components/main/Main";
import MainHeader from "../../../shared/components/main/MainHeader";

import Download from "./downloads/Een_bundeltje_tips_als_je_mama_wordt.pdf";
import Image from "./images/mama-document.png";

const DocumentMamaPage = () => {
  return (
    <Main>
      <Container>
        <MainHeader title="Hét e-book voor als je (weer) mama wordt" />
        <Flex justify="center">
          <VStack align="start" maxW="800px">
            <img src={Image} />
            <p>
              Als je een kindje krijgt of kinderen hebt, veranderen je keuzes:
              je wil natuurlijk je kind zien opgroeien! Je gaat misschien minder
              werken, je neemt meer huishoudelijke taken op je. En dat is
              logisch.
            </p>
            <p>
              Maar hoe maak je hierin een bewuste keuze? Want minder werken
              betekent minder (eigen) inkomen, minder pensioen en minder
              carrièrekansen. Dit e-book helpt je op een leuke manier bewuste
              keuzes te maken. En om mogelijkheden te zien hoe je dit voor jou
              het beste aan kunt aanpakken. Samen met je partner.
            </p>
            <Heading as="h2" variant="lg">
              Klik hieronder om het e-book te downloaden
            </Heading>
            <Button download as="a" href={Download} variant="outline">
              Download het e-book
            </Button>
          </VStack>
        </Flex>
      </Container>
    </Main>
  );
};

export default DocumentMamaPage;
