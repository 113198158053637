import { Heading } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import MainFooterItem from "../../../shared/components/main/MainFooterItem";
import { FaqAccordion, useAccordionItems } from "../faqAccordion/index";

export const AboutKandoorAccordion = () => {
  const { t } = useTranslation();
  const accordionItems = useAccordionItems("main:aboutKandoor.accordion");

  return (
    <MainFooterItem>
      <Heading as="h2" id="about-kandoor" variant="lg">
        {t("main:aboutKandoor.header")}
      </Heading>
      <FaqAccordion accordionItems={accordionItems} variant="basic" />
    </MainFooterItem>
  );
};
