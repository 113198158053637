import { Box, Link } from "@chakra-ui/react";
import React, { MouseEvent, useContext } from "react";
import { FaQuestionCircle } from "react-icons/fa";

import { Context } from "../../../shared/components/statemachine/contexts/StatemachineContext";
import useResource from "../../../shared/hooks/useResource";
import { QuestionFlowContext } from "../../../shared/lib/questionFlowContext";
import { singularize } from "../../../shared/lib/string";
import { EmbeddedDirectQuestionResource } from "../../../types/serializers";

interface EmbeddedDirectQuestionProps {
  embeddedDirectQuestion: EmbeddedDirectQuestionResource;
}

const EmbeddedDirectQuestion = ({
  embeddedDirectQuestion,
}: EmbeddedDirectQuestionProps) => {
  const { openFlow } = useContext(QuestionFlowContext);
  const wizardIdentifier = embeddedDirectQuestion.relationships.wizard.data;
  const target = useResource(embeddedDirectQuestion.relationships.target.data);
  const wizard = useResource(wizardIdentifier, {
    root: GLOBALS.statemachineRoot,
  });

  const questionText =
    embeddedDirectQuestion.attributes.questionText ??
    wizard?.attributes.question;

  if (!target || !wizardIdentifier || !questionText) {
    return null;
  }

  const referer = `${singularize(target.type)}_${target.id}_veelgesteld_wz_${
    wizardIdentifier.id
  }`;

  const handleClick = (e: MouseEvent) => {
    e.preventDefault();

    openFlow({
      context: Context.FrequentlyAsked,
      referer,
      wizardId: wizardIdentifier.id,
    });
  };

  return (
    <Link
      alignItems="center"
      as="button"
      backgroundColor="#E1ECFA"
      borderRadius="base"
      display="flex"
      marginBottom={3}
      paddingBottom={2}
      paddingTop={3}
      paddingX={5}
      width="100%"
      onClick={handleClick}
    >
      <Box
        flexGrow={1}
        fontSize="large"
        fontWeight="bold"
        marginRight="1em"
        textAlign="left"
      >
        {questionText}
      </Box>
      <Box flexShrink={0}>
        <FaQuestionCircle display="inline" />
      </Box>
    </Link>
  );
};

export default EmbeddedDirectQuestion;
