import React from "react";
import { Route } from "react-router-dom";

import Routes from "../shared/components/routes";
import RouteNotFound from "../shared/components/routes/RouteNotFound";
import { Action } from "../shared/components/store/Router";
import { useResourceRouter } from "../shared/hooks/useResourceRouter";
import { ResourceType } from "../types/serializers";

import CodeOfConduct from "./components/codeOfConduct";
import StatusHandler from "./components/statusHandler";
import BlogIndexPage from "./pages/blog/BlogIndexPage";
import BlogShowPage from "./pages/blog/BlogShowPage";
import BlogAuthorIndexPage from "./pages/blogAuthors/BlogAuthorIndexPage";
import BlogAuthorShowPage from "./pages/blogAuthors/BlogAuthorShowPage";
import ChatConversationShowPage from "./pages/chatConversations/ChatConversationShowPage";
import PillarPageIndexPage from "./pages/pillarPages/PillarPageIndexPage";
import PillarPageShowPage from "./pages/pillarPages/PillarPageShowPage";
import AboutPage from "./pages/static/AboutPage";
import AccountPage from "./pages/static/AccountPage";
import ConditionsPage from "./pages/static/ConditionsPage";
import ContactPage from "./pages/static/ContactPage";
import CookiesPage from "./pages/static/CookiesPage";
import DashboardPage from "./pages/static/DashboardPage";
import DocumentMamaPage from "./pages/static/DocumentMamaPage";
import DocumentsPage from "./pages/static/DocumentsPage";
import DocumentStopWorkPage from "./pages/static/DocumentStopWorkPage";
import GptNewPage from "./pages/static/GptNewPage";
import HomePage from "./pages/static/HomePage";
import PrivacyStatementPage from "./pages/static/PrivacyStatementPage";
import SignInPage from "./pages/static/SignInPage";
import StatemachinePage from "./pages/static/StatemachinePage";
import UpgradeBrowserPage from "./pages/static/UpgradeBrowserPage";
import VolunteersGuidelinesPage from "./pages/static/VolunteersGuidelinesPage";
import VolunteersPage from "./pages/static/VolunteersPage";

const MainRoutes = () => {
  const resourceRoute = useResourceRouter(StatusHandler);

  return (
    <Routes>
      <Route index element={<HomePage />} />
      {resourceRoute(
        ResourceType.BlogAuthor,
        Action.Index,
        BlogAuthorIndexPage
      )}
      {resourceRoute(ResourceType.BlogAuthor, Action.Show, BlogAuthorShowPage)}
      {resourceRoute(ResourceType.Blog, Action.Index, BlogIndexPage)}
      {resourceRoute(ResourceType.Blog, Action.Show, BlogShowPage)}
      {resourceRoute(
        ResourceType.ChatConversation,
        Action.Show,
        ChatConversationShowPage
      )}
      {resourceRoute(
        ResourceType.PillarPage,
        Action.Index,
        PillarPageIndexPage
      )}
      {resourceRoute(ResourceType.PillarPage, Action.Show, PillarPageShowPage)}
      <Route element={<StatemachinePage />} path="statemachine" />
      <Route element={<CookiesPage />} path="cookies" />
      <Route element={<AboutPage />} path="over-ons" />
      <Route element={<AccountPage />} path="account" />
      <Route element={<ConditionsPage />} path="conditions" />
      <Route element={<ContactPage />} path="contact" />
      <Route element={<DashboardPage />} path="dashboard" />
      <Route element={<GptNewPage />} path="gpt" />
      <Route element={<PrivacyStatementPage />} path="privacy" />
      <Route element={<UpgradeBrowserPage />} path="upgrade" />
      <Route element={<SignInPage />} path="users/sign_in" />
      <Route element={<VolunteersPage />} path="vrijwilligers" />
      <Route element={<CodeOfConduct />} path="code_of_conduct" />
      <Route element={<DocumentsPage />} path="documenten" />
      <Route
        element={<DocumentStopWorkPage />}
        path="documenten/eerder-stoppen-met-werken-stappenplan"
      />
      <Route
        element={<DocumentMamaPage />}
        path="documenten/als-je-mama-wordt"
      />
      <Route
        element={<VolunteersGuidelinesPage />}
        path="richtlijnen-vrijwilligers"
      />

      <Route element={<RouteNotFound />} path="*" />
    </Routes>
  );
};

export default MainRoutes;
