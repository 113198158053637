import { SkeletonText } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";

import useCurrentUser from "../../../shared/hooks/useCurrentUser";
import useHideQuestionFloatButton from "../../components/questionFlow/hooks/useHideQuestionFloatButton";

import SignInPage from "./SignInPage";
import UserAccountPage from "./UserAccountPage";
import VolunteerAccountPage from "./VolunteerAccountPage";

const AccountPage = () => {
  const [currentUser, loading] = useCurrentUser();
  useHideQuestionFloatButton();

  if (loading) {
    return <SkeletonText title={t("shared:status.loading")!} />;
  }

  if (!currentUser) {
    return <SignInPage />;
  }

  if (currentUser?.attributes.isGuide) {
    return <VolunteerAccountPage />;
  }

  return <UserAccountPage />;
};

export default AccountPage;
