import { useContext, useEffect } from "react";

import { QuestionFlowContext } from "../../../../shared/lib/questionFlowContext";

const useHideQuestionFloatButton = () => {
  const { setShowFloat } = useContext(QuestionFlowContext);

  useEffect(() => {
    setShowFloat(false);

    return () => setShowFloat(true);
  }, []);
};

export default useHideQuestionFloatButton;
