import {
  Box,
  Grid,
  HStack,
  Heading,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useContext } from "react";

import { AppContext } from "../../../shared/components/appContext/AppContextProvider";
import Container from "../../../shared/components/layout/Container";
import Link from "../../../shared/components/link";
import SimpleMarkdown from "../../../shared/components/simpleMarkdown";
import NewsletterForm from "../blog/NewsletterForm";
import SocialButton from "../buttons/SocialButton";
import PillarPageAccordion, {
  PillarPageAccordionVariant,
} from "../pillarPages/PillarPageAccordion";

interface LinkListProps {
  links: { text: string; url: string }[];
  title: string;
}

const GRID_COLUMNS_ACCORDION = {
  base: "repeat(1, 1fr)",
  sm: "repeat(3, 1fr)",
  md: "repeat(3, 1fr)",
  lg: "repeat(4, 1fr)",
  xl: "repeat(5, 1fr)",
};
const GRID_COLUMNS_LINKS = {
  base: "repeat(1, 1fr)",
  sm: "repeat(2, 1fr)",
  md: "repeat(3, 1fr)",
  lg: "repeat(4, 1fr)",
  xl: "repeat(5, 1fr)",
};

const LinkList: React.FC<LinkListProps> = ({ title, links }) => (
  <VStack align="start" h="100%" minW="180px" spacing={2}>
    <Text color="gray.800" fontWeight="bold">
      {title}
    </Text>
    {links.map((link) => (
      <Link href={link.url} key={link.url}>
        {link.text}
      </Link>
    ))}
  </VStack>
);

const Footer: React.FC = () => {
  const { showFooter } = useContext(AppContext);

  if (!showFooter) {
    return null;
  }

  return (
    <Box
      as="footer"
      background="background.footer"
      color="white"
      pb={10}
      position="relative"
      textAlign="left"
      zIndex="10"
    >
      <Box bg="primary.dark" p={3}>
        <Stack
          direction={{ base: "column", sm: "row" }}
          gap={{ base: "1em", sm: "3em" }}
          justifyContent="center"
        >
          <Box display={{ base: "none", md: "block" }}>
            <SimpleMarkdown>
              {t("main:footer.kandoorfacts.questions.desktop")}
            </SimpleMarkdown>
          </Box>
          <Box display={{ base: "block", md: "none" }}>
            <SimpleMarkdown>
              {t("main:footer.kandoorfacts.questions.mobile")}
            </SimpleMarkdown>
          </Box>
          <Box display={{ base: "none", md: "block" }}>
            <SimpleMarkdown>
              {t("main:footer.kandoorfacts.rating.desktop")}
            </SimpleMarkdown>
          </Box>
          <Box display={{ base: "block", md: "none" }}>
            <SimpleMarkdown>
              {t("main:footer.kandoorfacts.rating.mobile")}
            </SimpleMarkdown>
          </Box>
        </Stack>
      </Box>
      <Container color="blackish">
        <Heading paddingBottom="1em" paddingTop="2em" variant="sm">
          {t("main:footer.pillarPages")}
        </Heading>
        <Box borderBottom="1px solid #DDDDDD" paddingBottom="1em">
          <PillarPageAccordion
            className="footer-pillar-pages"
            gridColumns={GRID_COLUMNS_ACCORDION}
            variant={PillarPageAccordionVariant.Footer}
          />
        </Box>
        <Box mt={8}>
          <Heading paddingBottom="1em" variant="sm">
            {t("main:footer.moreLinks")}
          </Heading>
          <Grid
            borderBottom="1px solid #DDDDDD"
            gap={6}
            pb={8}
            templateColumns={GRID_COLUMNS_LINKS}
          >
            <LinkList
              links={[
                {
                  text: t("main:footer.items.volunteers"),
                  url: "/vrijwilligers",
                },
                { text: t("main:footer.items.about"), url: "/over-ons" },
                { text: t("main:footer.items.contact"), url: "/contact" },
              ]}
              title={t("main:footer.linklist.about")}
            />

            <LinkList
              links={[
                { text: t("main:footer.items.sign_in"), url: "/users/sign_in" },
                { text: t("main:footer.items.blogs"), url: "/blogs" },
                { text: t("main:footer.items.conditions"), url: "/conditions" },
                { text: t("main:footer.items.privacy"), url: "/privacy" },
                { text: t("main:footer.items.cookies"), url: "/cookies" },
                { text: t("main:footer.items.documents"), url: "/documenten" },
              ]}
              title={t("main:footer.linklist.directly_to")}
            />
            <Box color="blackish">
              <NewsletterForm source="footer" />
            </Box>
          </Grid>
          <Grid
            gap={6}
            marginTop={8}
            templateColumns={{ base: "repeat(1, 1fr)", md: "2fr 1fr 2fr" }}
          >
            <Box align-items="start" order={{ base: 1, md: 0 }}>
              <Text
                color="gray.800"
                textAlign={{ base: "center", md: "left" }}
                textStyle="light"
              >
                {t("main:footer.copyright", { year: new Date().getFullYear() })}
              </Text>
            </Box>
            <HStack
              justifyContent="center"
              order={{ base: 0, md: 1 }}
              spacing={3}
            >
              <SocialButton social="fb" />
              <SocialButton social="insta" />
              <SocialButton social="linkedin" />
            </HStack>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
