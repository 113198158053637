import { Box, Flex, Heading } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";

import ReadButton from "../buttons/ReadButton";

import InterviewCoverImage from "./images/henriette_interview_cover.webp";

export const VolunteerInterviewCover: React.FC = () => {
  return (
    <Flex direction="column">
      <Heading as="h2" my="2em" variant="md">
        {t("main:volunteers.interview.title")}
      </Heading>
      <Flex direction={{ base: "column", md: "row" }} gap={8}>
        <Box
          flex="1 1 0px"
          mr={{ base: "0", md: "2em" }}
          mt={{ base: "2em", md: "0" }}
          position="relative"
        >
          <Box
            backgroundImage={`url(${InterviewCoverImage})`}
            backgroundPosition="100% center"
            backgroundSize="cover"
            borderRadius={{ base: "8px 8px 0px 0px", md: "0px 8px 0px 0px" }}
            height={{ base: "350px", md: "400px", lg: "600px" }}
            width="100%"
          />
          <Box
            background={{
              base: "linear-gradient(to bottom, transparent 85%, white)",
            }}
            borderRadius={{ base: "8px 8px 0px 0px", md: "0px" }}
            display={{ base: "", md: "none" }}
            height="100%"
            position="absolute"
            top={{
              base: "1",
              md: "0",
            }}
            width="100%"
          />
        </Box>
        <Box flex="0.5 1 0px" mb="2em" pr={4}>
          <Box lineHeight="2" mb="2em">
            {t("main:volunteers.interview.intro")}
          </Box>
          <ReadButton href="/kennisbank/schulden/interview" variant="solid">
            {t("main:volunteers.interview.button")}
          </ReadButton>
        </Box>
      </Flex>
    </Flex>
  );
};
