import { Box } from "@chakra-ui/react";
import React from "react";

import { JsonAPIData } from "../shared/components/store/StoreContextProvider";
import { setCsrfTokenFromDocument } from "../shared/lib/api";
import { Globals } from "../types/globals";

import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar";
import QuestionFlowProvider from "./components/questionFlow/QuestionFlowProvider";
import Wrappers from "./components/wrappers";
import MainRoutes from "./MainRoutes";

import "./lib/polyfills";

interface Main {
  globals?: Globals;
  path: string;
  seed: JsonAPIData;
}

const Main = ({ globals, path, seed }: Main) => {
  if (globals) {
    (global as any).GLOBALS = globals;
  }
  setCsrfTokenFromDocument();

  return (
    <Wrappers path={path} seed={seed}>
      <QuestionFlowProvider>
        <Navbar />
        <Box textStyle="body">
          <MainRoutes />
          <Footer />
        </Box>
      </QuestionFlowProvider>
    </Wrappers>
  );
};

export default Main;
