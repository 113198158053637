import {
  Flex,
  Heading,
  ListItem,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React from "react";

import Container from "../../../shared/components/layout/Container";
import Main from "../../../shared/components/main/Main";
import MainHeader from "../../../shared/components/main/MainHeader";

const CookiesPage = () => {
  return (
    <Main>
      <Container>
        <MainHeader title="Cookies op Kandoor" />
        <Flex justify="center">
          <VStack align="start" maxW="800px">
            <Heading as="h2" mb={0} variant="lg">
              Wat zijn cookies en hoe gebruikt Kandoor ze?
            </Heading>
            <p>
              Door jouw gebruik van Kandoor kunnen cookies op jouw computer
              worden geplaatst, en kan toegang tot die cookies worden verkregen.
              Cookies zijn kleine stukjes (tekst) informatie die een server naar
              de applicatie of browser stuurt met de bedoeling dat deze
              applicatie of browser de informatie bij een volgend bezoek weer
              naar de server terug zal sturen. Cookies van Kandoor kunnen je
              computer of de bestanden die op je computer staan niet
              beschadigen.
              <br />
              Vooropgesteld: Kandoor werkt prima als je geen cookies accepteert,
              hoewel je vaker opnieuw moet inloggen. Als je ze accepteert kunnen
              we Kandoor wat gebruiksvriendelijker maken en word je bij een
              volgend bezoek herkend.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Cookies van Kandoor
            </Heading>
            <p>
              Bij het gebruik van Kandoor worden eigen cookies van Kandoor
              geplaatst. Bij het plaatsen en uitlezen van deze zogenaamde first
              party cookies zijn geen derde partijen betrokken. Alleen Kandoor
              heeft toegang tot die cookies. Kandoor plaatst in de eerste plaats
              functionele cookies die noodzakelijk zijn om de dienst te kunnen
              leveren. Daarnaast maakt Kandoor gebruik van zogenaamde
              sessie-cookies. De informatie die in deze cookies wordt opgeslagen
              bevat geen naam- of adresgegevens of andere persoonsgegevens en de
              cookies worden verwijderd zodra je je gebruik van Kandoor
              beëindigt. Kandoor kan met behulp van deze gegevens de website
              aanpassen aan de behoeftes van de bezoekers.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Cookies van derde partijen
            </Heading>
            <p>
              Buiten de cookies van Kandoor zelf, worden er via de website ook
              cookies van derde partijen - zogenaamde third party cookies -
              geplaatst op jouw computer, mobiele telefoon of tablet. Cookies
              waarmee wij webstatistieken verzamelen over het gebruik en bezoek
              van Kandoor.nl gebruiken wij om de website te verbeteren en alle
              bezoekers een optimale gebruikerservaring te bieden. Op onze
              website maken wij gebruik van analytische cookies. Het betreft de
              volgende cookies:
            </p>
            <UnorderedList>
              <ListItem>Google Analytics</ListItem>
              <ListItem>Microsoft Clarity</ListItem>
            </UnorderedList>
            <p>
              Daarnaast gebruiken wij cookies om de effectiviteit van onze
              advertentiecampagnes te meten. Dit betreft de volgende cookies:
            </p>
            <UnorderedList>
              <ListItem>Facebook</ListItem>
              <ListItem>Google Ads</ListItem>
            </UnorderedList>
            <Heading as="h2" mb={0} variant="lg">
              Ingesloten video&apos;s
            </Heading>
            <p>
              Op onze website staan ingesloten video&apos;s van YouTube. Het is
              mogelijk dat er cookies geplaatst worden bij het laden en afspelen
              van deze video&apos;s. Voor meer informatie, kun je de
              <a
                href="https://www.youtube.com/howyoutubeworks/our-commitments/protecting-user-data/#privacy-guidelines"
                rel="noreferrer"
                target="_blank"
              >
                privacyverklaring
              </a>{" "}
              van YouTube bekijken. van YouTube bekijken.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Verwijderen cookies
            </Heading>
            <p>
              Je kunt jouw browser zo instellen dat je tijdens je volgende
              bezoek aan Kandoor geen niet-essentiële cookies ontvangt. In dat
              geval kan het echter gebeuren dat je niet van alle mogelijkheden
              van Kandoor gebruik kunt maken of dat je geen toegang hebt tot
              (onderdelen van) Kandoor. Om meer over deze functies te weten te
              komen, kun je het beste je browser instructies of de Help-functie
              van je browser raadplegen.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Wijzigen Cookieverklaring
            </Heading>
            <p>
              Kandoor heeft het recht om deze cookieverklaring te wijzigen. Lees
              deze cookieverklaring dus regelmatig. Deze cookieverklaring is
              voor het laatst gewijzigd op 23 mei 2024.
            </p>
          </VStack>
        </Flex>
      </Container>
    </Main>
  );
};

export default CookiesPage;
