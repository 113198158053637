import { Box, SimpleGrid, chakra } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import Collection from "../../../shared/components/collection";
import CollectionItems from "../../../shared/components/collection/CollectionItems";
import CollectionPagination from "../../../shared/components/collection/CollectionPagination";
import CollectionSearch from "../../../shared/components/collection/CollectionSearch";
import CollectionSortFilter from "../../../shared/components/collection/CollectionSortFilter";
import CollectionSortFilterButton from "../../../shared/components/collection/CollectionSortFilterButton";
import Main from "../../../shared/components/main/Main";
import MainBody from "../../../shared/components/main/MainBody";
import MainHeader from "../../../shared/components/main/MainHeader";
import MainLeft from "../../../shared/components/main/MainLeft";
import useBlogFilterOptions from "../../../shared/hooks/useBlogFilterOptions";
import useBlogSortOptions from "../../../shared/hooks/useBlogSortOptions";
import { CollectionResource, ResourceType } from "../../../types/serializers";
import BlogPreview from "../../components/blog/BlogPreview";
import Breadcrumbs from "../../components/breadcrumbs";
import Layout3_9 from "../../layouts/Layout3_9";

const GRID_COLUMNS = { base: 1, sm: 2, md: 3, lg: 3 };

const BlogIndexPage = ({
  resource,
}: {
  resource: CollectionResource<ResourceType.Blog>;
}) => {
  const { t } = useTranslation();
  const availableSorts = useBlogSortOptions();
  const availableFilters = useBlogFilterOptions();

  return (
    <Main>
      <Collection<ResourceType.Blog>
        fullPage
        availableFilters={availableFilters}
        availableSorts={availableSorts}
        id={resource.id}
      >
        <Layout3_9>
          <Breadcrumbs
            items={[{ href: "/", label: t("main:breadcrumbs.home") }]}
          />
          <MainHeader
            pullRight={
              <chakra.div display={{ lg: "none" }}>
                <CollectionSortFilterButton />
              </chakra.div>
            }
            title={t("main:blogs.heading")}
          />
          <MainLeft>
            <Box paddingBottom="1em">
              <CollectionSearch />
            </Box>
            <chakra.div display={{ base: "none", lg: "block" }}>
              <CollectionSortFilter />
            </chakra.div>
          </MainLeft>
          <MainBody>
            <SimpleGrid columns={GRID_COLUMNS} spacing="40px">
              <CollectionItems<ResourceType.Blog>>
                {(blogItem) => <BlogPreview details blog={blogItem} />}
              </CollectionItems>
            </SimpleGrid>
            <CollectionPagination />
          </MainBody>
        </Layout3_9>
      </Collection>
    </Main>
  );
};

export default BlogIndexPage;
