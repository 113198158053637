import { IconButton, Image } from "@chakra-ui/react";
import React from "react";

import Fb from "../images/fb_logo.png";
import Insta from "../images/insta_logo.png";
import Linkedin from "../images/linkedin_logo.png";

const socials = {
  fb: {
    logo: Fb,
    site: "Facebook",
    url: "https://www.facebook.com/kandoor.nl/",
  },
  insta: {
    logo: Insta,
    site: "Instagram",
    url: "https://www.instagram.com/kandoor_nl",
  },
  linkedin: {
    logo: Linkedin,
    site: "Linkedin",
    url: "https://nl.linkedin.com/company/kandoor-nederland",
  },
};

interface SocialButtonProps {
  social: keyof typeof socials;
}

const SocialButton: React.FC<SocialButtonProps> = ({ social }) => {
  const { logo, site, url } = socials[social];

  return (
    <IconButton
      aria-label={site}
      as="a"
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      variant="ghost"
    >
      <Image alt={site} boxSize="32px" objectFit="contain" src={logo} />
    </IconButton>
  );
};

export default SocialButton;
