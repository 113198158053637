import { GridItem, Heading, SimpleGrid } from "@chakra-ui/react";
import React from "react";

import RichText from "../../../shared/components/richText";
import useResource from "../../../shared/hooks/useResource";
import { EmptyResource, ResourceType } from "../../../types/serializers";
import PageRow from "../../layouts/PageRow";

import PillarPageBlockBlogs from "./PillarPageBlockBlogs";
import PillarPageBlockFaqs from "./PillarPageBlockFaqs";
import PillarPageBlockHighlight from "./PillarPageBlockHighlight";
import PillarPageBlockDirectQuestions from "./PillarPageDirectQuestions";

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const GRID_COLUMNS = { base: 1, md: 2 };
const POSITION_LEFT = 0;
const POSITION_RIGHT = 1;

const PillarPageBlock = ({
  even,
  pillarPageBlock: pillarPageBlockData,
}: {
  even: boolean;
  pillarPageBlock: EmptyResource<ResourceType.PillarPageBlock>;
}) => {
  const pillarPageBlock = useResource(pillarPageBlockData);

  if (!pillarPageBlock) {
    return null;
  }

  return (
    <PageRow backdrop={!even}>
      <SimpleGrid columns={GRID_COLUMNS} spacing={12}>
        <GridItem
          key="body"
          order={{
            base: POSITION_LEFT,
            md: even ? POSITION_LEFT : POSITION_RIGHT,
          }}
        >
          <Heading as="h2" marginTop="-10px" variant="lg">
            {pillarPageBlock.attributes.title}
          </Heading>
          <RichText textStyle="bodyBlog">
            {pillarPageBlock.attributes.body}
          </RichText>
        </GridItem>
        <GridItem
          key="richContent"
          order={{
            base: POSITION_RIGHT,
            md: even ? POSITION_RIGHT : POSITION_LEFT,
          }}
        >
          <PillarPageBlockFaqs
            embeddedFaqs={pillarPageBlock.relationships.embeddedFaqs.data}
            faqHeading={pillarPageBlock.attributes.faqHeading}
          />
          <PillarPageBlockHighlight
            highlightBody={pillarPageBlock.attributes.highlightBody}
            highlightTitle={pillarPageBlock.attributes.highlightTitle}
          />
          <PillarPageBlockBlogs
            blogs={pillarPageBlock.relationships.embeddedBlogs.data}
          />
          <PillarPageBlockDirectQuestions
            directQuestions={
              pillarPageBlock.relationships.embeddedDirectQuestions.data
            }
          />
        </GridItem>
      </SimpleGrid>
    </PageRow>
  );
};

export default PillarPageBlock;
