import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";

type AboutAnecdoteProps = {
  imageSrc: string;
  name: string;
  text: string;
};

export const AboutAnecdote: React.FC<AboutAnecdoteProps> = ({
  imageSrc,
  name,
  text,
}) => {
  return (
    <Box p={2}>
      <Flex alignItems="center">
        <Image
          alt={`${name}'s profile`}
          borderRadius="full"
          boxSize="80px"
          mb="1em"
          mr="1em"
          objectFit="cover"
          src={imageSrc}
        />
        <Text fontWeight="bold" mb={1}>
          {name}
        </Text>
      </Flex>
      <Text lineHeight="2em">{text}</Text>
    </Box>
  );
};
