import { Box, Heading, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { AboutAnecdote } from "./AboutAnecdote";

type AboutAnecdoteProps = {
  imageSrc: string;
  name: string;
  text: string;
};

type AboutAnecdotesProps = {
  anecdotes: AboutAnecdoteProps[];
};

export const AboutAnecdotes: React.FC<AboutAnecdotesProps> = ({
  anecdotes,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box>
        <Heading as="h2" variant="md">
          {t("main:about.anecdotes.title")}
        </Heading>
      </Box>
      <Box>
        <SimpleGrid alignItems="start" columns={{ sm: 1, md: 2, lg: 3 }}>
          {anecdotes.map((anecdote, index) => (
            <AboutAnecdote key={index} {...anecdote} />
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};
