import React from "react";

import RichText from "../../../../../shared/components/richText";
import ChatCard from "../ChatCard";
import { MessageRenderer } from "../ChatMessage";

const StatemachineMessage = ({
  chatConversation,
  chatMessage,
}: MessageRenderer) => {
  return (
    <ChatCard
      chatConversation={chatConversation}
      createdAt={chatMessage.attributes.createdAt}
      creator={chatMessage.relationships.user.data}
    >
      <RichText>{chatMessage.attributes.content}</RichText>
    </ChatCard>
  );
};

export default StatemachineMessage;
