import {
  Flex,
  Heading,
  ListItem,
  OrderedList,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React from "react";

import Container from "../../../shared/components/layout/Container";
import Link from "../../../shared/components/link";
import Main from "../../../shared/components/main/Main";
import MainHeader from "../../../shared/components/main/MainHeader";

const ConditionsPage = () => {
  return (
    <Main>
      <Container>
        <MainHeader title="Gebruikersvoorwaarden" />
        <Flex justify="center">
          <VStack align="start" maxW="800px">
            <Heading as="h2" mb={0} variant="lg">
              Wat is Kandoor?
            </Heading>
            <p>
              Kandoor is een gratis online platform voor financiële vragen.
              Kandoor is een onderdeel van APG Groep N.V., de grootste
              pensioenuitvoerder van Nederland.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Wat kun je van Kandoor.nl verwachten?
            </Heading>
            <p>
              Op het Kandoorplatform kun je financiële vragen stellen.
              Bijvoorbeeld over pensioen, belasting, schulden, studie, werk,
              wonen of een ander financieel onderwerp. Onze wizard en onze
              vrijwilligers, financiële professionals, geven je kosteloos
              antwoord. Vrijwilligers helpen je zonder financiële
              tegenprestatie. Gewoon, omdat het een goed gevoel geeft en zij er
              zelf van leren.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Gebruikersvoorwaarden Kandoor en privacyverklaring
            </Heading>
            <p>
              Om het Kandoorplatform goed te laten functioneren en aan iedereen
              een prettige omgeving te bieden, geldt er een aantal voorwaarden.
              Als je gebruik maakt van het Kandoorplatform (door bijvoorbeeld
              een vraag te stellen), ga je akkoord met de gebruikersvoorwaarden
              van Kandoor en de privacyverklaring. De privacyverklaring
              beschrijft welke gegevens van jou worden verwerkt en hoe deze
              verwerkingen plaatsvinden.Om het Kandoorplatform goed te laten
              functioneren en aan iedereen een prettige omgeving te bieden,
              geldt er een aantal voorwaarden. Als je gebruik maakt van het
              Kandoorplatform (door bijvoorbeeld een vraag te stellen), ga je
              akkoord met de gebruikersvoorwaarden van Kandoor en de
              privacyverklaring. De privacyverklaring beschrijft welke gegevens
              van jou worden verwerkt en hoe deze verwerkingen plaatsvinden.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Wat is wel en wat is niet toegestaan op Kandoor.nl
            </Heading>
            <p>
              Gebruikers behandelen elkaar met respect. Gebruikers maken geen
              racistische, beledigende, seksistische, discriminerende,
              bedreigende of andere kwetsende opmerkingen. Ze gebruiken geen
              grof taalgebruik, maken geen misplaatste grappen en vallen andere
              gebruikers niet lastig. Gebruikers plaatsen geen pornografisch of
              ander aanstootgevend materiaal.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Verder is het gebruikers niet toegestaan om:
            </Heading>
            <OrderedList>
              <ListItem>
                de werking van het online platform te storen door vaak dezelfde
                tekst of tekens te plaatsen;
              </ListItem>
              <ListItem>
                de werking van het online platform, de website, andere computers
                of systemen van Kandoor en andere gebruikers te verstoren.
                Daaronder valt:
                <UnorderedList>
                  <ListItem>
                    het verspreiden van virussen (inclusief Trojaanse paarden,
                    tijdbommen en dergelijke);
                  </ListItem>
                  <ListItem>
                    het hacken of ander toegang geven tot de computers of
                    systemen van Kandoor of andere gebruikers;
                  </ListItem>
                  <ListItem>
                    het wissen van bestanden op computers of systemen van
                    Kandoor of andere gebruikers.
                  </ListItem>
                </UnorderedList>
              </ListItem>
            </OrderedList>
            <p>Het online platform mag niet worden gebruikt voor:</p>
            <OrderedList>
              <ListItem>
                commerciële activiteiten. Bijvoorbeeld het aanprijzen of de
                verkoop van producten of diensten, het sturen van commerciële
                boodschappen (spam), het aanbieden van (interactieve) spelletjes
                of het aanprijzen van andere chatkanalen of websites;
              </ListItem>
              <ListItem>
                wettelijk niet toegestane activiteiten en handelingen, zoals de
                handel in soft- en harddrugs, de handel in of verspreiding van
                (kinder)porno, de handel of verspreiding van bijvoorbeeld
                mp3-bestanden of andere zaken waar auteursrecht op zit.
              </ListItem>
            </OrderedList>
            <p>Gebruikers zullen zich onthouden van:</p>
            <OrderedList>
              <ListItem>
                leugens te vertellen over Kandoor of Kandoor te beschuldigen
                zonder dat Kandoor zich heeft kunnen verdedigen;
              </ListItem>
              <ListItem>
                het hengelen naar contacten. Kandoor.nl is geen datingsite;
              </ListItem>
              <ListItem>
                het vragen om antwoorden en/of reacties via privébericht,
                telefoon of per e-mail.
              </ListItem>
            </OrderedList>
            <Heading as="h2" mb={0} variant="lg">
              Gedragsregels bij het plaatsen van berichten
            </Heading>
            <p>
              Wees helder in de vraagstelling en/of opmerking. Houd de tekst van
              je bericht leesbaar.
            </p>
            <p>
              Wees begripvol en respectvol richting andere gebruikers,
              vrijwilligers en moderators.
            </p>
            <p>
              Gebruik geen shockerende of ongepaste afbeeldingen bij je bericht
              of profielfoto. Plaats geen illegale en/of shockerende teksten.
              Vraag ook niet om illegale en/of shockerende teksten.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Zorgvuldigheid
            </Heading>
            <p>
              Kandoor adviseert gebruikers om het platform zorgvuldig te
              gebruiken. Een paar tips:
            </p>
            <UnorderedList>
              <ListItem>
                Maak geen persoonlijke informatie aan andere gebruikers bekend.
                Denk daarbij aan adressen, telefoonnummers, bankrekeningnummers
                en creditcardnummers.
              </ListItem>
              <ListItem>
                Zorg voor goede beveiliging van je computer(s) en andere
                systemen. Installeer hiervoor software of hardware die toegang
                tot jouw computer(s) of systemen blokkeert. Zorg er daarnaast
                voor dat jouw computer(s) of systemen geen virussen verspreiden.
                Denk hierbij aan firewalls en antivirus software. Zorg er tot
                slot voor dat deze software up-to-date is.
              </ListItem>
            </UnorderedList>
            <Heading as="h2" mb={0} variant="lg">
              Aansprakelijkheid
            </Heading>
            <p>
              Hoewel Kandoor de grootst mogelijke zorg besteedt aan haar
              platform, biedt Kandoor geen enkele garantie, expliciet of
              impliciet, met betrekking tot de juistheid, volledigheid of
              tijdigheid van de informatie dan wel voor het foutloos en
              ononderbroken functioneren van Kandoor. Tevens aanvaardt Kandoor
              (waaronder mede wordt verstaan haar vrijwilligers) geen enkele
              aansprakelijkheid voor schade die voortvloeit uit het gebruik van
              het Kandoor platform (hieronder valt bijvoorbeeld schade gebaseerd
              op jouw handelen naar aanleiding van informatie die je op Kandoor
              hebt verkregen). De op of via het platform aangeboden informatie
              is enkel en alleen bedoeld voor algemene informatiedoeleinden (om
              je een richting te geven bij het maken van eigen keuzes) en is
              niet bedoeld als enige vorm van deskundig (financieel, juridisch
              of fiscaal) advies. Voor specifiek advies met betrekking tot je
              persoonlijke situatie raden wij je aan om contact op te nemen met
              een professionele adviseur.
            </p>
            <p>
              De gebruiker vrijwaart hierbij Kandoor voor alle schade en kosten
              die Kandoor lijdt of maakt als gevolg van aanspraken van andere
              gebruikers of derden, die worden veroorzaakt door handelingen van
              de gebruiker, die in strijd zijn met deze gebruikersvoorwaarden of
              die worden veroorzaakt door onrechtmatig handelen van die
              gebruiker op het Kandoor platform.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Copyright
            </Heading>
            <p>
              Kandoor behoudt het recht om ingezonden materiaal in te korten en
              aan te passen. Een bezoeker van Kandoor.nl mag geen
              auteursrechtelijk beschermde werken of andere in Kandoor
              opgeslagen informatie openbaar maken of verveelvoudigen zonder
              toestemming van Kandoor.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Intellectuele eigendomsrechten
            </Heading>
            <p>
              Alle (intellectuele) eigendomsrechten met betrekking tot de naam
              Kandoor en het logo van Kandoor, inclusief de merkrechten,
              berusten uitsluitend bij APG Groep N.V.. De (auteurs-)rechten met
              betrekking tot het format en de inhoud van de website Kandoor
              (onder meer de daarin opgenomen gegevens, afbeeldingen, geluiden,
              teksten, look & feel, lay-out) berusten uitsluitend bij APG Groep
              N.V.. Zonder voorafgaande schriftelijke toestemming van APG Groep
              N.V. is het onder meer niet toegestaan de website Kandoor.nl, of
              enig onderdeel daarvan, openbaar te maken en/of te (doen)
              verveelvoudigen (waaronder in ieder geval begrepen aanbieden,
              (doen) vervaardigen, verkopen, leveren of anderszins verhandelen).
              Zogenaamde ‘framing’ valt uitdrukkelijk onder het op ontoelaatbare
              wijze openbaar maken/verveelvoudigen van (onderdelen van) de
              website.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Minderjarigen
            </Heading>
            <p>
              Gebruikers onder de 16 jaar dienen toestemming te hebben van hun
              ouders of wettelijke vertegenwoordiger voor het deelnemen aan
              Kandoor onder toepasselijkheid van deze gebruikersvoorwaarden.
              Kandoor aanvaardt geen enkele aansprakelijkheid indien dit niet
              het geval blijkt te zijn. Kandoor behoudt zich het recht voor om
              gebruikers onder de 16 jaar van deelname uit te sluiten en/of hun
              materiaal te verwijderen van Kandoor.nl indien zij niet kunnen
              aantonen dat zij de vereiste toestemming hebben gekregen.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Klachtenregeling
            </Heading>
            <p>
              Vind je dat het gedrag van andere gebruikers of van vrijwilligers
              niet past binnen de gebruikersvoorwaarden? Stuur dan een e-mail
              aan <Link href="mailto:info@kandoor.nl">info@kandoor.nl</Link>.
              Als we merken dat een gebruiker of financieel vrijwilliger zich
              niet aan de gebruikersvoorwaarden houdt, nemen we maatregelen. Zo
              passen we bijvoorbeeld berichten aan of verwijderen we een
              bericht. Soms vragen we een gebruiker of financieel vrijwilliger
              de berichten zelf aan te passen of te verwijderen. Ook kunnen we
              een gebruiker of financieel vrijwilliger tijdelijk of permanent
              uitsluiten van toegang tot het Kandoor platform.
            </p>
            <Heading as="h2" mb={0} variant="lg">
              Diversen
            </Heading>
            <p>
              APG Groep N.V. behoudt zich het recht voor het Kandoor platform te
              beëindigen zonder dat hier verder voor haar negatieve
              consequenties aan verbonden zijn. Wanneer een deel van deze
              gebruikersvoorwaarden onwettig, ongeldig of niet afdwingbaar wordt
              verklaard, laat dit de andere delen van deze gebruikersvoorwaarden
              ongemoeid.
            </p>
            <p>
              Kandoor heeft het recht om deze gebruikersvoorwaarden op ieder
              moment te veranderen wanneer zij dit nodig acht. We raden je aan
              om regelmatig zelf de gebruikersvoorwaarden te lezen.
            </p>
            <p>
              Op de inhoud van deze gebruikersvoorwaarden is Nederlands recht
              van toepassing. In geval van een geschil is de rechter
              arrondissement Amsterdam bevoegd.
            </p>
            <p>
              De huidige versie is voor het laatst gewijzigd op 23 mei 2024.
            </p>
          </VStack>
        </Flex>
      </Container>
    </Main>
  );
};

export default ConditionsPage;
