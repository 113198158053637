import { Box } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { useLocation } from "react-router-dom";

import Container from "../../../shared/components/layout/Container";
import { Loader } from "../../../shared/components/loader";
import Main from "../../../shared/components/main/Main";
import MainBody from "../../../shared/components/main/MainBody";
import MainHeader from "../../../shared/components/main/MainHeader";
import useCurrentUser from "../../../shared/hooks/useCurrentUser";
import useAppearanceSubscription from "../../components/Chat/hooks/useAppearanceSubscription";
import CodeOfConduct from "../../components/codeOfConduct";
import VolunteerTabs from "../../components/dashboard/VolunteerTabs";
import useHideFooter from "../../components/footer/hooks/useHideFooter";
import useHideQuestionFloatButton from "../../components/questionFlow/hooks/useHideQuestionFloatButton";
import usePageViewport from "../../hooks/usePageViewport";

const DashboardPage = () => {
  const [currentUser, userLoading] = useCurrentUser();
  useHideQuestionFloatButton();
  useHideFooter();
  useAppearanceSubscription();
  const { top } = usePageViewport();
  const location = useLocation();

  if (userLoading) {
    return <Loader />;
  }

  if (!currentUser?.attributes.isGuide) {
    return (
      <Main>
        <Container>
          <Box>{t("main:dashboard.noAccess")}</Box>
        </Container>
      </Main>
    );
  }

  const maxHeight =
    location.hash === "#archive"
      ? undefined
      : `min(calc(100vh - 98px + ${top}px), calc(100vh + 150px))`;

  if (!currentUser.attributes.codeOfConductApprovedAt) {
    return <CodeOfConduct />;
  }

  return (
    <Main>
      <Container
        display="flex"
        flexDirection="column"
        height={maxHeight}
        overflow="auto"
      >
        <MainHeader pageTitle={t("main:dashboard.header")} />
        <MainBody display="flex" height="100%" minHeight="25em">
          <VolunteerTabs />
        </MainBody>
      </Container>
    </Main>
  );
};

export default DashboardPage;
