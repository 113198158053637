import { ListItem, UnorderedList } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

import Container from "../../../shared/components/layout/Container";
import Main from "../../../shared/components/main/Main";
import MainHeader from "../../../shared/components/main/MainHeader";

const DocumentsPage = () => {
  return (
    <Main>
      <Container>
        <MainHeader title="Documenten" />
        <UnorderedList>
          <ListItem>
            <Link to="/documenten/eerder-stoppen-met-werken-stappenplan">
              Eerder stoppen met werken stappenplan
            </Link>
          </ListItem>
          <ListItem>
            <Link to="/documenten/als-je-mama-wordt">
              Hét e-book voor als je (weer) mama wordt
            </Link>
          </ListItem>
        </UnorderedList>
      </Container>
    </Main>
  );
};

export default DocumentsPage;
