import React from "react";
import { useTranslation } from "react-i18next";

import Main from "../../../shared/components/main/Main";
import AnjaIcon from "../../components/volunteers/images/anja.webp";
import BobIcon from "../../components/volunteers/images/bob.webp";
import LeoIcon from "../../components/volunteers/images/leo.webp";
import PetraIcon from "../../components/volunteers/images/petra.webp";
import { VolunteerAnecdotes } from "../../components/volunteers/VolunteerAnecdotes";
import VolunteerHeader from "../../components/volunteers/VolunteerHeader";
import { VolunteerInterviewCover } from "../../components/volunteers/VolunteerInterviewCover";
import PageRow from "../../layouts/PageRow";

const VolunteersPage = () => {
  const { t } = useTranslation();

  const volunteerAnecdote = [
    {
      imageSrc: BobIcon,
      volunteerName: t("main:volunteers.anecdotes.bob.name"),
      volunteerText: t("main:volunteers.anecdotes.bob.text"),
    },
    {
      imageSrc: PetraIcon,
      volunteerName: t("main:volunteers.anecdotes.petra.name"),
      volunteerText: t("main:volunteers.anecdotes.petra.text"),
    },
    {
      imageSrc: AnjaIcon,
      volunteerName: t("main:volunteers.anecdotes.anja.name"),
      volunteerText: t("main:volunteers.anecdotes.anja.text"),
    },
    {
      imageSrc: LeoIcon,
      volunteerName: t("main:volunteers.anecdotes.leo.name"),
      volunteerText: t("main:volunteers.anecdotes.leo.text"),
    },
  ];

  return (
    <Main marginBottom={0}>
      <VolunteerHeader />
      <PageRow backdrop={false} paddingY={0}>
        <VolunteerInterviewCover />
      </PageRow>
      <PageRow backdrop={true} paddingY={8}>
        <VolunteerAnecdotes anecdotes={volunteerAnecdote} />
      </PageRow>
    </Main>
  );
};

export default VolunteersPage;
