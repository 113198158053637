import { Box, Heading, Text } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface ContactCardProps {
  children: ReactNode;
  description: string;
  title: string;
}

const ContactCard: React.FC<ContactCardProps> = ({
  title,
  description,
  children,
}) => (
  <Box
    bg="gray.lighter"
    borderRadius="md"
    display="grid"
    flex="1"
    gridTemplateRows="auto 1fr auto"
    maxW={{ base: "100%", md: "350px", lg: "430px" }}
    minW={{ base: "100%", md: "350px" }}
    p="4"
    textAlign="left"
  >
    <Heading mb="4" size="md">
      {title}
    </Heading>
    <Text mb="4" minHeight={20}>
      {description}
    </Text>
    <Box mt="auto">{children}</Box>
  </Box>
);

export default ContactCard;
