import { Box, GridItem, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import EditResourceButton from "../../../shared/components/button/EditResourceButton";
import Image from "../../../shared/components/image";
import Container from "../../../shared/components/layout/Container";
import Main from "../../../shared/components/main/Main";
import MainHeader from "../../../shared/components/main/MainHeader";
import RichText from "../../../shared/components/richText";
import useIndexRoute from "../../../shared/components/store/hooks/useIndexRoute";
import useResource from "../../../shared/hooks/useResource";
import { PillarPageResource, ResourceType } from "../../../types/serializers";
import Breadcrumbs from "../../components/breadcrumbs";
import QuestionButton from "../../components/buttons/QuestionButton";
import PillarPageBlock from "../../components/pillarPageBlock";

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const GRID_COLUMNS = { base: 1, md: 2 };

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const isEven = (val: number) => val % 2 == 0;

const PillarPageShowPage = ({ resource }: { resource: PillarPageResource }) => {
  const { t } = useTranslation();
  const coverImage = useResource(resource.relationships.coverImage.data);
  const indexRoute = useIndexRoute(ResourceType.PillarPage);

  return (
    <Main marginBottom={0}>
      <Container>
        <Breadcrumbs
          items={[
            { href: "/", label: t("main:breadcrumbs.home") },
            {
              href: indexRoute,
              label: t("main:pillarPages.breadcrumb"),
            },
          ]}
        />
        <MainHeader
          pullRight={<EditResourceButton resource={resource} />}
          title={resource.attributes.title}
        >
          <SimpleGrid
            borderBottom="1px solid #DDDDDD"
            columns={GRID_COLUMNS}
            paddingBottom={10}
            spacing={12}
          >
            <GridItem>
              <RichText textStyle="bodyBlog">
                {resource.attributes.body}
              </RichText>
              <Box paddingBottom="2em" textStyle="bodyBlog">
                {t("main:pillarPages.askQuestion.intro")!}
              </Box>
              <QuestionButton referer={`pillar_page_${resource.id}`}>
                {t("main:pillarPages.askQuestion.button")!}
              </QuestionButton>
            </GridItem>
            <GridItem>
              {coverImage && (
                <Image
                  alt={coverImage.attributes.alt}
                  baseEncoded={coverImage.attributes.baseEncoded}
                  borderRadius="0.5em"
                  caption={coverImage.attributes.caption}
                  height={coverImage.attributes.coverHeight}
                  marginBottom="2em"
                  src={coverImage.attributes.coverUrl}
                  width={coverImage.attributes.coverWidth}
                />
              )}
            </GridItem>
          </SimpleGrid>
        </MainHeader>
      </Container>
      {resource.relationships.pillarPageBlocks.data?.map(
        (pillarPageBlock, index) => (
          <PillarPageBlock
            even={isEven(index!)}
            key={pillarPageBlock.id}
            pillarPageBlock={pillarPageBlock}
          />
        )
      )}
    </Main>
  );
};

export default PillarPageShowPage;
