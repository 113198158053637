import React, { FC } from "react";

import useResource from "../../../../shared/hooks/useResource";
import {
  ChatConversationResource,
  ChatMessageResource,
  ChatMessageType,
  EmptyResource,
  ResourceType,
} from "../../../../types/serializers";

import ErrorMessage from "./messages/ErrorMessage";
import FeedbackCard from "./messages/FeedbackCard";
import HelpedQuery from "./messages/HelpedQuery";
import QuestionReceivedMessage from "./messages/QuestionReceivedMessage";
import RecommendBlogMessage from "./messages/RecommendBlogMessage";
import RichTextMessage from "./messages/RichTextMessage";
import SendToVolunteerMessage from "./messages/SendToVolunteerMessage";
import StatemachineMessage from "./messages/StatemachineMessage";
import SystemMessage from "./messages/SystemMessage";
import TextMessage from "./messages/TextMessage";

interface ChatMessageProps {
  message: EmptyResource<ResourceType.ChatMessage>;
}

export interface MessageRenderer {
  chatConversation: ChatConversationResource;
  chatMessage: ChatMessageResource;
  handleKandoorRequest: (message: string) => void;
}

const RENDERERS: {
  [key in ChatMessageType]: FC<MessageRenderer>;
} = {
  [ChatMessageType.UserMessage]: TextMessage,
  [ChatMessageType.GptMessage]: RichTextMessage,
  [ChatMessageType.FeedbackQuery]: TextMessage,
  [ChatMessageType.SystemMessage]: SystemMessage,
  [ChatMessageType.ErrorMessage]: ErrorMessage,
  [ChatMessageType.Statemachine]: StatemachineMessage,
  [ChatMessageType.RecommendedBlog]: RecommendBlogMessage,
  [ChatMessageType.HelpedQuery]: (props) =>
    [true, false].includes(
      useResource(props.chatMessage.relationships.feedback?.data)?.attributes
        ?.helpedQuery as any
    ) ? (
      <FeedbackCard {...props} />
    ) : (
      <HelpedQuery {...props} />
    ),
  [ChatMessageType.SendToVolunteer]: SendToVolunteerMessage,
  [ChatMessageType.QuestionReceived]: QuestionReceivedMessage,
  [ChatMessageType.JourneyContent]: TextMessage,
};

export const ChatMessage: React.FC<
  ChatMessageProps & {
    chatConversation: ChatConversationResource;
    handleKandoorRequest: (message: string) => void;
  }
> = ({ message, handleKandoorRequest, chatConversation }) => {
  const chatMessage = useResource(message);

  if (!chatMessage) {
    return null;
  }

  const Renderer = RENDERERS[chatMessage.attributes.messageType];
  if (!Renderer) {
    throw new Error(
      `unknown message type: ${chatMessage.attributes.messageType}`
    );
  }

  const rendererProps: MessageRenderer = {
    chatConversation,
    chatMessage,
    handleKandoorRequest,
  };

  return <Renderer {...rendererProps} />;
};
